:root {
  --fontSize: 15px;
  /*--emsAcrossViewport: 80;
  --fontSize: calc(100vw / var(--emsAcrossViewport));*/

  --lineHeight: calc(var(--fontSize) * 1.5);

  --spacing: 2rem;
  --spacingv: calc(var(--lineHeight) / 2);

  --columnGap: 2rem;

  /* colors */
  --red: color(display-p3 0.94 0.19 0.04);
  --yellow: color(display-p3 1 0.96 0.05);
  --warm-yellow: color(display-p3 1 0.87 0.05);
  --light-yellow: color(display-p3 1 1 0.7);
  --blue: rgb(3, 102, 230);
  --green: rgb(38, 220, 78);
  --mediumGreen: rgb(119, 221, 134);

  --font-family: "InterVariable";
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font: 15px/22px var(--font-family), system-ui, sans-serif;
  font-size: var(--fontSize);
  line-height: var(--lineHeight);
  letter-spacing: -0.004em;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  font-kerning: normal;
  -webkit-font-kerning: normal;
  -ms-font-kerning: normal;
  -moz-font-kerning: normal;
  -o-font-kerning: normal;
  font-variant-ligatures: contextual common-ligatures;
  -webkit-font-variant-ligatures: contextual common-ligatures;
  -ms-font-variant-ligatures: contextual common-ligatures;
  -moz-font-variant-ligatures: contextual common-ligatures;
  -o-font-variant-ligatures: contextual common-ligatures;
  font-feature-settings: "kern" 1, "liga" 1, "calt" 1;
}

a {
  text-decoration: none;
}

.darkmode .brick.backgrounded ::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.07);
}

.react-rte *,
.richtext-viewer * {
  z-index: 0 !important;
  font-family: InterVariable !important;
  font-size: 14px;
}

.richtext-viewer {
  border: none !important;
  background: transparent !important;
}

.richtext-viewer .public-DraftEditor-content {
  padding: 0 !important;
}

.public-DraftEditor-content {
  min-height: 150px;
}
/* 
.spinner {
  --spinner-size: 2rem;
  --line-color: #ff8400;
  --line-alpha: 1;
  --ring-color: #00000020;
  --ring-alpha: 0.3;
  --ring-size: 7;

  font-size: calc(var(--spinner-size) * 1em);
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}
.spinner .line {
  fill: none;
  stroke: var(--line-color);
  stroke-width: var(--ring-size);
  opacity: var(--line-alpha);
  stroke-linecap: round;
  transform-origin: 50% 50%;
  transform: rotate3d(0, 0, 1, 0deg);
  animation: 2156ms spinner-arc ease-in-out infinite,
    1829ms spinner-rotate linear infinite;
}
.spinner .ring {
  fill: none;
  stroke: var(--ring-color);
  stroke-width: var(--ring-size);
  opacity: var(--ring-alpha);
}
@keyframes spinner-rotate {
  to {
    transform: rotate3d(0, 0, 1, 360deg);
  }
}
@keyframes spinner-arc {
  from {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dasharray: 100 150;
    stroke-dashoffset: -140;
  }
} */

.react-share-button:focus {
  outline: none;
}

.link a {
  text-decoration: underline;
  color: #222222;
}

@keyframes blink-animation {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes blink-animation {
  to {
    opacity: 0;
  }
}

.dot:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 10px;
  height: 10px;
  background-color: #de6f3f;
  border-radius: 50%;
}

.dot:after {
  content: " ";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #de6f3f;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3) inset;
  -webkit-animation-name: "ripple";
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
}

@keyframes ripple {
  0% {
    left: 5px;
    top: 5px;
    opcity: 75;
    width: 0;
    height: 0;
  }
  100% {
    left: -20px;
    top: -20px;
    opacity: 0;
    width: 50px;
    height: 50px;
  }
}

.clickable:hover .linkText {
  text-decoration: underline;
  color: #de6f3f;
}
